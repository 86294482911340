.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-black);

  padding: 1rem 2rem;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
}

.app__navbar-logo img {
  width: 80px;
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
  cursor: pointer;
}
.app__navbar-links li:hover {
  color: var(--color-grey);
}

.app__navbar-smallscreen {
  display: none;
}

.app__navbar-smallscreen_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);
  transition: 0.5s ease;

  flex-direction: column;
  z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
  font-size: 27px;
  color: var(--color-yellow);
  cursor: pointer;

  position: absolute;
  top: 20px;
  right: 20px;
}

.app__navbar-smallscreen_links {
  list-style: none;
}

.app__navbar-smallscreen_links li {
  margin: 2rem;
  cursor: pointer;
  color: var(--color-yellow);
  font-size: 2rem;
  text-align: center;
  font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
  color: var(--color-white);
}

@media screen and (max-width: 2000px) {
  /* .app__navbar-logo img {
        width: 100px;
        padding-left:90px ;
      } */
}

@media screen and (max-width: 1150px) {
  .app__navbar-links {
    display: none;
  }
  .app__navbar-smallscreen {
    display: flex;
  }
}

@media screen and (max-width: 650px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar-logo {
    padding-left: 1rem;
  }

  .app__navbar-logo img {
    width: 70px;
  }
}
