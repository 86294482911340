.app__menuitem {
  width: 100%;
  margin: 1rem 0;

  display: flex;
  flex-direction: column;
}

.app__menuitem-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.app__menuitem-name {
  flex: 1;
}

.app__menuitem-dash {
  width: 119px;
  height: 1px;

  background: var(--color-yellow);
  margin: 0 1rem;
}

.app__menuitem-price {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-items: flex-end;
}
